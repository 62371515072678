import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Controls from "../../components/controls/Controls";
import Display from "../../components/display/Display";
import Navigation from "../../components/navigation/Navigation";
import Feedback from "../../components/feedback/Feedback";
import { getPropertyList } from './PropertiesServices';
import { getPropertyTypeList } from './PropertiesTypesServices';
import { getPropertyDestinationList } from './PropertiesDestinationsServices';
import { getPropertyStateList } from './PropertiesStatesServices';
import room from '../../assets/images/icons/room.png'
import area from '../../assets/images/icons/area.png'
import bathroom from '../../assets/images/icons/bathroom.png'
import garage from '../../assets/images/icons/garage.png'
import './Property.scss';

const data = [
    {},
    {},
    {},
    {}
];

const itemAction = [
    { id: 'Arriendo', name: "Arriendo" },
    { id: 'Compra', name: "Compra" }
];

const itemsStrata = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" }
];

const itemsRangeArr = [
    { id: 1, name: "$ 0 - $ 999.000" },
    { id: 2, name: "$ 1.000.000 - $ 1.999.000" },
    { id: 3, name: "$ 2.000.000 - $ 2.999.000" },
    { id: 4, name: "$ +3.000.000" }
];

const itemsRangeSale = [
    { id: 1, name: "$ 0 - $ 149.999.000" },
    { id: 2, name: "$ 150.000.000 - $ 299.999.000" },
    { id: 3, name: "$ 300.000.000 - $ 499.000.000" },
    { id: 4, name: "$ +500.000.000" }
];

const itemsTags = [
    {"id": `Habitación: 3`, "index": 0, "value": 3, "type": "room"},
    {"id": `Baño: 2`, "index": 0, "value": 2, "type": "bathroom"}
];

export default function PropertyView() {
    const param = new URLSearchParams(window.location.search);
    const [getActionSearch, setActionSearch] = useState(param.get("action"));
    const [getSearch, setSearch] = useState(param.get("search"));
    const [getLoading, setLoading] = useState(true);
    const [getOpenType, setOpenType] = useState(false);
    const [getOpenDestination, setOpenDestination] = useState(false);
    const [getOpenState, setOpenState] = useState(false);
    const [arrayAction, setAction] = useState(itemAction);
    const [getRecordsLoading, setRecordsLoading] = useState(data);
    const [getRecords, setRecords] = useState([]);
    const [getTotalRows, setTotalRows] = useState(0);
    const [getPages, setPages] = useState(0);
    const [getPage, setPage] = useState(0);
    const [getDrawerOpen, setDrawerOpen] = useState(false);
    const [arrayPropertyType, setArrayPropertyType] = useState([]);
    const [getPropertyTypeOpen, setPropertyTypeOpen] = useState(false);
    const [getPropertyType, setPropertyType] = useState(null);
    const [arrayType, setArrayType] = useState([]);
    const [arrayPropertyDestination, setArrayPropertyDestination] = useState([]);
    const [getPropertyDestinationOpen, setPropertyDestinationOpen] = useState(false);
    const [getPropertyDestination, setPropertyDestination] = useState(null);
    const [arrayDestination, setArrayDestination] = useState([]);
    const [arrayPropertyState, setArrayPropertyState] = useState([]);
    const [getPropertyStateOpen, setPropertyStateOpen] = useState(false);
    const [getPropertyState, setPropertyState] = useState(null);
    const [arrayState, setArrayState] = useState([]);
    const [getArrayTags, setArrayTags] = useState(itemsTags);
    const [arrayStrata, setArrayStrata] = useState(itemsStrata);
    const [getStrataOpen, setStrataOpen] = useState(false);
    const [getStrata, setStrata] = useState(null);
    const [arrayStrato, setArrayStrato] = useState([]);
    const [getRoomOpen, setRoomOpen] = useState(true);
    const [getRoom, setRoom] = useState(3);
    const [getBathroomOpen, setBathroomOpen] = useState(true);
    const [getBathroom, setBathroom] = useState(2);
    const [getGarageOpen, setGarageOpen] = useState(false);
    const [getGarage, setGarage] = useState('');
    const [arrayRange, setArrayRange] = useState([]);
    const [arrayRanges, setArrayRanges] = useState([]);
    const [getRangeOpen, setRangeOpen] = useState(false);
    const [getRange, setRange] = useState(null);
    const [getAreaOpen, setAreaOpen] = useState(false);
    const [getArea1, setArea1] = useState('');
    const [getArea2, setArea2] = useState('');
    const [getYearOpen, setYearOpen] = useState(false);
    const [getYear1, setYear1] = useState('');
    const [getYear2, setYear2] = useState('');
    const [getStateType, setStateType] = useState(false);
    const [getStateDestiny, setStateDestiny] = useState(false);
    const [getStateState, setStateState] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const navigate = useNavigate();

    const handleOpenType = () => {
        setOpenType(!getOpenType);
    };

    const handleOpenDestination = () => {
        setOpenDestination(!getOpenDestination);
    };

    const handleOpenState = () => {
        setOpenState(!getOpenState);
    };

    const setHandleRange = (actionSearch = null) => {
        let filter;
        setArrayRange([]);

        if (actionSearch === null) {
            filter = getActionSearch;
        } else {
            filter = actionSearch;
        }

        if (filter === 'Arriendo'){
            setArrayRange(itemsRangeArr);
        }

        if (filter === 'Compra'){
            setArrayRange(itemsRangeSale);
        }
    }

    useEffect(() => {
        setHandleRange();
    },[getActionSearch]);

    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    },[]);

    useEffect(() => {
        const header = document.querySelector(".subheader");

        if (scrollTop > 300) {
            header.classList.add('sticky');
            header.classList.add('animate__animated');
            header.classList.add('animate__slideInDown');
        } else {
            header.classList.remove('sticky');
            header.classList.remove('animate__animated');
            header.classList.remove('animate__slideInDown');
        }
    }, [scrollTop]);

    const setHandleChangeAction = async(actionSearch) => {
        await navigate(`/propiedades?action=${actionSearch}&search=${getSearch}`, { replace: true });
        setHandleRange(actionSearch);
        getHandlePropertyList(getPage, actionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
    };

    const setHandleSearch = async() => {
        if (getActionSearch !== "" && getSearch !== '') {
            await navigate(`/propiedades?action=${getActionSearch}&search=${getSearch}`, { replace: true });
            setHandleRange();
            getHandlePropertyList(getPage, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
        }
    };

    const getHandlePropertyList = async (page, queryAction, types = null, destination = null, state = null, strata = null, number_room = null, number_bathroom = null, number_garage = null, range = null, area1 = null, area2 = null, year1 = null, year2 = null) => {
        setLoading(true);

        const result = {
            "page" : page,
            "action" : queryAction,
            "search" : getSearch === '' ? '' : getSearch,
            "type" : types === null ? null : types,
            "destination" : destination === null ? null : destination,
            "state" : state === null ? null : state,
            "strata" : strata === '' ? null : strata,
            "number_room" : number_room === '' ? null : number_room,
            "number_bathroom" : number_bathroom === '' ? null : number_bathroom,
            "number_garage" : number_garage === '' ? null : number_garage,
            "range" : range === '' ? null : range,
            "area1" : area1 === '' ? null : area1,
            "area2" : area2 === '' ? null : area2,
            "year1" : year1 === '' ? null : year1,
            "year2" : year2 === '' ? null : year2,
            "realstate": 1,
            "save": true
        };

        const res = await getPropertyList(result);

        if (res.status === 200) {
            setPages(res.message.nbPages);
            setRecords(res.message.hits);
            setTotalRows(res.message.nbHits);
        }

        setLoading(false);
    };

    useEffect(() => {
        getHandlePropertyList(getPage, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
    }, []);

    const setHandleDrawer = (value) => {
        setDrawerOpen(value);

        if (value){
            getHandlePropertyTypeList();
            getHandlePropertyDestinationList();
            getHandlePropertyStateList();
        }
    }

    const setHandleOpenPropertyType = (value) => {
        setPropertyTypeOpen(!value);
    };

    const setHandleOpenPropertyDestination = (value) => {
        setPropertyDestinationOpen(!value);
    };

    const setHandleOpenPropertyState = (value) => {
        setPropertyStateOpen(!value);
    };

    const setHandleOpenStrata = (value) => {
        setStrataOpen(!value);
    };

    const setHandleOpenRoom = (value) => {
        setRoomOpen(!value);
    };

    const setHandleOpenBathroom = (value) => {
        setBathroomOpen(!value);
    };

    const setHandleOpenGarage = (value) => {
        setGarageOpen(!value);
    };

    const setHandleOpenRange = (value) => {
        setRangeOpen(!value);
    };

    const setHandleOpenArea = (value) => {
        setAreaOpen(!value);
    };

    const setHandleOpenYear = (value) => {
        setYearOpen(!value);
    };

    const getHandlePropertyTypeList = async () => {
        const res = await getPropertyTypeList();

        if (res.status === 200) {
            setArrayPropertyType(res.message.hits);
        }
    };

    const getHandlePropertyDestinationList = async () => {
        const res = await getPropertyDestinationList();

        if (res.status === 200) {
            setArrayPropertyDestination(res.message.hits);
        }
    };

    const getHandlePropertyStateList = async () => {
        const res = await getPropertyStateList();

        if (res.status === 200) {
            setArrayPropertyState(res.message.hits);
        }
    };

    const setHandleClearFilter = async () => {
        arrayType.splice(0, arrayType.length);
        arrayDestination.splice(0, arrayDestination.length);
        arrayState.splice(0, arrayState.length);
        arrayStrato.splice(0, arrayStrato.length);
        arrayRanges.splice(0, arrayRanges.length);
        itemsTags.splice(0, itemsTags.length);
        setPage(0);
        setArrayType([]);
        setArrayDestination([]);
        setArrayState([]);
        setArrayStrato([]);
        setArrayRanges([]);
        setArrayTags([]);
        setPropertyType(null);
        setPropertyDestination(null);
        setPropertyState(null);
        setStrata(null);
        setRoom('');
        setBathroom('');
        setGarage('');
        setRange(null);
        setArea1('');
        setArea2('');
        setYear1('');
        setYear2('');
        setPropertyTypeOpen(false);
        setOpenType(false);
        setPropertyDestinationOpen(false);
        setOpenDestination(false);
        setPropertyStateOpen(false);
        setOpenState(false);
        setStrataOpen(false);
        setRoomOpen(false);
        setBathroomOpen(false);
        setGarageOpen(false);
        setRangeOpen(false);
        setAreaOpen(false);
        setYearOpen(false);

        await navigate(`/propiedades?action=${getActionSearch}&search=${getSearch}`, { replace: true });

        getHandlePropertyList(0, getActionSearch);
    };

    const setHandleDeleteTag = async (value) => {
        let room = getRoom;
        let bathroom = getBathroom;
        let garage = getGarage;
        setArrayTags([]);

        itemsTags.forEach((item, index, map) => {
            if(item.id === value.id) {
                if (value.type === 'range') {
                    arrayRanges.forEach((itemType, indexType, mapType) => {
                        if(itemType.id === value.index) {
                            mapType.splice(indexType, 1);
                        }
                    });

                    setRange(arrayRanges);

                    if (arrayRanges.length === 0) {
                        setRangeOpen(false);
                    }
                }

                if (value.type === 'type') {
                    arrayType.forEach((itemType, indexType, mapType) => {
                        if(itemType.id === value.index) {
                            mapType.splice(indexType, 1);
                        }
                    });

                    setPropertyType(arrayType);

                    if (arrayType.length === 0) {
                        setPropertyTypeOpen(false);
                        setOpenType(false);
                    }
                }

                if (value.type === 'destination') {
                    arrayDestination.forEach((itemDestination, indexDestination, mapDestination) => {
                        if(itemDestination.id === value.index) {
                            mapDestination.splice(indexDestination, 1);
                        }
                    });

                    setPropertyDestination(arrayDestination);

                    if (arrayDestination.length === 0) {
                        setPropertyDestinationOpen(false);
                        setOpenDestination(false);
                    }
                }

                if (value.type === 'state') {
                    arrayState.forEach((itemState, indexState, mapState) => {
                        if(itemState.id === value.index) {
                            mapState.splice(indexState, 1);
                        }
                    });

                    setPropertyState(arrayState);

                    if (arrayState.length === 0) {
                        setPropertyStateOpen(false);
                        setOpenState(false);
                    }
                }

                if (value.type === 'strata') {
                    arrayStrato.forEach((itemStrata, indexStrata, mapStrata) => {
                        if(itemStrata.id === value.index) {
                            mapStrata.splice(indexStrata, 1);
                        }
                    });

                    setStrata(arrayStrato);

                    if (arrayStrato.length === 0) {
                        setStrataOpen(false);
                    }
                }

                if (value.type === 'room') {
                    room = null;
                    setRoom('');
                    setRoomOpen(false);
                }

                if (value.type === 'bathroom') {
                    bathroom = null;
                    setBathroom('');
                    setBathroomOpen(false);
                }

                if (value.type === 'garage') {
                    garage = null;
                    setGarage('');
                    setGarageOpen(false);
                }

                map.splice(index, 1);
            }
        });

        setArrayTags(itemsTags);

        await navigate(`/propiedades?action=${getActionSearch}&search=${getSearch}`, { replace: true });

        getHandlePropertyList(getPage, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, room, bathroom, garage, getRange, getArea1, getArea2, getYear1, getYear2);
    };

    const setHandleChangePage = (value) => {
        setPage(value);

        getHandlePropertyList(value, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
    };

    return (
        <>
            <div className="subheader">
                <div className="container">
                    <Controls.SearchFull
                        classDropdown="btn btn-outline-secondary dropdown-toggle btn-Dropdown-light font-family-raleway-bold font-size-18 color-primary auto140 height-40"
                        labelDropDown={getActionSearch}
                        arrayDropdown={arrayAction}
                        classText="form-control form-control-primary bg-primary-light font-family-raleway-bold font-size-18 color-dark height-40 mt-7"
                        nameText="search"
                        valueText={getSearch}
                        placeholder="Buscar por ubicación, zona y dirección"
                        onChangeText={e => setSearch(e.target.value)}
                        onKeyPress={e => e.charCode === 13 && setHandleSearch(e, getActionSearch)}
                        classButton="btn btn-outline-primary text-white width-40 height-40 p-0 mt-7"
                        onClickButton={e => setHandleSearch(e, getActionSearch)}
                        iconButton={<Display.Icons.SearchIcon />}
                        classButtonDrawer="font-family-raleway-bold font-size-18 auto140 height-40"
                        colorButtonDrawer="blueLight"
                        labelButtonDrawer="Más filtros"
                        valueDrawer={getDrawerOpen}
                        setAction={setActionSearch}
                        setSearching={setHandleSearch}
                        setChangeSearching={setHandleChangeAction}
                        setDrawer={setHandleDrawer}
                    />
                </div>
            </div>

            <div className="sectionSearchContent">
                <div className="font-size-20 font-family-raleway-bold color-primary mb-5">
                    <div className="container">
                        <Navigation.Link label="Inicio" href="/" />
                        <span className="m-2">|</span>
                        <span>{getActionSearch}</span>
                    </div>
                </div>

                <div className="color-dark mb--40">
                    <div className="container">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="text-truncate font-family-raleway-bold font-size-40 height-40 pt-2">{getSearch}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="font-size-20"><span className="font-family-open-sans-bold">{getTotalRows}</span> <span className="font-family-raleway-bold">disponibles</span></div>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <div className="container">
                    <div className="sectionSearch">
                        <Controls.SearchFull
                            classDropdown="btn btn-outline-secondary dropdown-toggle btn-Dropdown-light font-family-raleway-bold font-size-18 color-primary auto140 height-60"
                            labelDropDown={getActionSearch}
                            arrayDropdown={arrayAction}
                            classText="form-control form-control-primary bg-primary-light font-family-raleway-bold font-size-18 color-dark height-60 mt-7"
                            nameText="search"
                            valueText={getSearch}
                            placeholder="Buscar por ubicación, zona y dirección"
                            onChangeText={e => setSearch(e.target.value)}
                            onKeyPress={setHandleSearch}
                            classButton="btn btn-outline-primary text-white width-60 height-60 mt-7"
                            onClickButton={setHandleSearch}
                            iconButton={<Display.Icons.SearchIcon />}
                            classButtonDrawer="font-family-raleway-bold font-size-18 auto140 height-60"
                            colorButtonDrawer="blueLight"
                            labelButtonDrawer="Más filtros"
                            valueDrawer={getDrawerOpen}
                            setAction={setActionSearch}
                            setSearching={setHandleSearch}
                            setChangeSearching={setHandleChangeAction}
                            setDrawer={setHandleDrawer}
                        />
                    </div>
                </div>
            </div>

            <div className="cardContent">
                <div className="container">
                    <Grid container spacing={1}>
                        {
                            getLoading ?
                                getRecordsLoading.length > 0 && (
                                    getRecordsLoading.map((item, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                            <Card>
                                                <Feedback.Loading type="rect" animation="wave" width="100%" height="250px" className="card-media" />
                                                <CardContent>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Feedback.Loading type="text" animation="wave" width="80%" height="25px" />
                                                            <Feedback.Loading type="text" animation="wave" width="60%" height="25px" />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Feedback.Loading type="text" animation="wave" width="100%" height="25px" />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Feedback.Loading type="text" animation="wave" width="100%" height="25px" />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Feedback.Loading type="text" animation="wave" width="100%" height="25px" />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Feedback.Loading type="text" animation="wave" width="100%" height="25px" />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                                <CardActions className="bg-primary-light2 padding-10">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Feedback.Loading type="text" animation="wave" width="40%" height="25px" />
                                                            <Feedback.Loading type="text" animation="wave" width="60%" height="25px" />
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))
                                )
                            :
                                <>
                                    {
                                        getRecords.length === 0 ?
                                            <Grid item xs={12}>
                                                <div className="font-family-raleway-bold font-size-20 color-primary text-center">
                                                    No hay propiedades que cohincidan con sun búsqueda. <br />
                                                    Limpie los filtros para encontrar más inmuebles <br /><br />
                                                    <Controls.Button
                                                        label="Limpiar filtros"
                                                        color="blue"
                                                        variant="contained"
                                                        onClick={() => setHandleClearFilter()}
                                                    />
                                                </div>
                                            </Grid>
                                        :
                                            getRecords.map((item, index) => (
                                                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                                                    <Card>
                                                        {
                                                            item.image_path === null ?
                                                                <div className="card-media">
                                                                    <div className="font-family-raleway-bold font-size-16 color-semidark position-relative m-auto text-center">
                                                                        <strong>{item.image_name}</strong>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <CardMedia
                                                                    className="card-media"
                                                                    image={item.image_path}
                                                                    title={item.image_name}
                                                                />
                                                        }
                                                        <CardContent>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <div className="font-family-raleway-bold font-size-16 color-primary text-capitalize text-truncate">
                                                                        {item.type}{item.neighborhood === null ? '' : ', ' + item.neighborhood}
                                                                    </div>
                                                                    <div className="font-family-raleway-medium font-size-14 color-dark text-capitalize">
                                                                        {item.city}{item.country === null ? '' : ', ' + item.country}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <div className="font-family-open-sans-bold font-size-13 color-dark text-center">
                                                                        <img src={area} width="30" height="30"/>
                                                                        <div className="font-family-open-sans-bold font-size-13 color-dark">{item.area_total}</div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <div className="font-family-open-sans-bold font-size-13 color-dark text-center">
                                                                        <img src={room} width="30" height="30"/>
                                                                        <div className="font-family-open-sans-bold font-size-13 color-dark">{item.number_room}</div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <div className="font-family-open-sans-bold font-size-13 color-dark text-center">
                                                                        <img src={bathroom} width="30" height="30"/>
                                                                        <div className="font-family-open-sans-bold font-size-13 color-dark">{item.number_bathroom}</div>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <div className="font-family-open-sans-bold font-size-13 color-dark text-center">
                                                                        <img src={garage} width="30" height="30"/>
                                                                        <div className="font-family-open-sans-bold font-size-13 color-dark">{item.number_garage}</div>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                        <CardActions className="bg-primary-light2 padding-10">
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <div className="font-family-raleway-medium font-size-14 color-dark">
                                                                        {item.action_type}
                                                                    </div>
                                                                    <div className="font-family-open-sans-bold font-size-18 color-primary">
                                                                        {
                                                                            item.action_type === 'Arriendo' ?
                                                                                `$ ${item.action_canon_string}`
                                                                            :
                                                                                `$ ${item.action_sale_string}`
                                                                        }
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                            ))
                                    }
                                </>
                        }
                        {
                            getRecords.length > 0 && (
                                <Grid item xs={12} className="mt-3">
                                    <Display.Pagination
                                        pageCount={getPages}
                                        pageIndex={getPage}
                                        setChangePage={setHandleChangePage}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
            </div>
            <Drawer
                className="d-flex drawer"
                variant="persistent"
                anchor="right"
                open={getDrawerOpen}
            >
                <Paper className="paper">
                    <div className="drawer-header">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div className="font-family-raleway-semibold font-size-18 color-white">
                                    Filtros
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <IconButton className="icon-drawer-header" onClick={() => setHandleDrawer(false)}>
                                    <Display.Icons.HighlightOffOutlinedIcon className="color-white" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="sectionTags">
                        <div className="sectionTagsHeader">
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    <div className="font-family-raleway-bold font-size-16 color-dark">
                                        Filtros seleccionados
                                    </div>
                                </Grid>
                                <Grid item xs={5}>
                                    <div className="font-family-raleway-regular font-size-14 color-primary float-right cursor-pointer" onClick={() => setHandleClearFilter()}>
                                        Limpiar todo
                                        <span className="sectionTagsHeaderButtonIcon">
                                            <IconButton>
                                                <Display.Icons.CloseOutlinedIcon />
                                            </IconButton>
                                        </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        {
                            getArrayTags.length > 0 && (
                                <div className="sectionTagsFooter">
                                    {
                                        getArrayTags.map((item, index) => (
                                            <div key={index} className="badge font-family-open-sans-regular">
                                                {item.id}
                                                <span className="badgeIcon">
                                                    <IconButton onClick={(e) => setHandleDeleteTag(item)}>
                                                        <Display.Icons.CloseOutlinedIcon />
                                                    </IconButton>
                                                </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className="font-family-raleway-bold font-size-16 color-dark"
                    >
                        <ListItem
                            button
                            onClick={() => setHandleOpenRange(getRangeOpen)}
                        >
                            Rango de precio
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getRangeOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getRangeOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>

                                        {
                                            arrayRange.length > 0 && (
                                                arrayRange.map((item, index) => (
                                                    <ListItem
                                                        button
                                                        key={index}
                                                        className="list-group-item d-flex text-nowrap font-family-raleway-regular"
                                                        onClick={() => {
                                                            const find = arrayRanges.find(element => element.id == item.id);

                                                            if (find === undefined || find === 'undefined'){
                                                                arrayRanges.push({"id": item.id});
                                                                itemsTags.push({"id": `Precio: ${item.name}`, "index": item.id, "value": item.name, "type": "range"});
                                                            } else {
                                                                arrayRanges.forEach((value, index, map) => {
                                                                    if(value.id === item.id) {
                                                                        map.splice(index, 1);
                                                                    }
                                                                });

                                                                itemsTags.forEach((value, index, map) => {
                                                                    if(value.id === `Precio: ${item.name}`) {
                                                                        map.splice(index, 1);
                                                                    }
                                                                });

                                                                if (arrayRanges.length === 0) {
                                                                    setRangeOpen(false);
                                                                }
                                                            }

                                                            setRange(arrayRanges);
                                                            setArrayTags(itemsTags);

                                                            getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, arrayRanges, getArea1, getArea2, getYear1, getYear2);
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                        {
                                                            (arrayRanges.find(element => element.id == item.id) === undefined) ?
                                                                (
                                                                    <Display.Icons.CheckBoxOutlineBlankOutlinedIcon />
                                                                )
                                                            :
                                                                (
                                                                    <Display.Icons.CheckBoxOutlinedIcon />
                                                                )
                                                        }
                                                        </ListItemIcon>
                                                        <ListItemText className="text-truncate font-family-open-sans-regular" primary={item.name} />
                                                    </ListItem>
                                                ))
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenPropertyType(getPropertyTypeOpen)}
                        >
                            Tipos de inmueble
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getPropertyTypeOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getPropertyTypeOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary dropdown-toggle btn-Dropdown-light font-family-raleway-regular font-size-16 color-primary height-40 width-100p"
                                            onClick={handleOpenType}
                                        >
                                            Seleccionar
                                        </button>
                                        {
                                            getOpenType && (
                                                <List
                                                    component="nav"
                                                    className="animate__slideInDown z-index-100 bg-white d-block"
                                                >
                                                    {
                                                        arrayPropertyType.map((item, index) => (
                                                            <ListItem
                                                                key={index}
                                                                className="dropdown-item text-nowrap text-truncate font-family-raleway-regular width-100p"
                                                                onClick={() => {
                                                                    const find = arrayType.find(element => element.id == item.id);

                                                                    if (find === undefined || find === 'undefined') {
                                                                        arrayType.push({"id": item.id});
                                                                        itemsTags.push({"id": `Tipo: ${item.name}`, "index": item.id, "value": item.name, "type": "type"});
                                                                    } else {
                                                                        arrayType.forEach((value, index, map) => {
                                                                            if (value.id === item.id) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        itemsTags.forEach((value, index, map) => {
                                                                            if (value.id === `Tipo: ${item.name}`) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        if (arrayType.length === 0) {
                                                                            setPropertyTypeOpen(false);
                                                                            setOpenType(false);
                                                                        }
                                                                    }

                                                                    setPropertyType(arrayType);
                                                                    setArrayTags(itemsTags);

                                                                    getHandlePropertyList(0, getActionSearch, arrayType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                {
                                                                    (arrayType.find(element => element.id == item.id) === undefined) ?
                                                                        (
                                                                            <Display.Icons.CheckBoxOutlineBlankOutlinedIcon/>
                                                                        )
                                                                    :
                                                                        (
                                                                            <Display.Icons.CheckBoxOutlinedIcon/>
                                                                        )
                                                                }
                                                                </ListItemIcon>
                                                                <ListItemText className="text-truncate" primary={item.name}/>
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenPropertyDestination(getPropertyDestinationOpen)}
                        >
                            Destinaciones
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getPropertyDestinationOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getPropertyDestinationOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary dropdown-toggle btn-Dropdown-light font-family-raleway-regular font-size-16 color-primary height-40 width-100p"
                                            onClick={handleOpenDestination}
                                        >
                                            Seleccionar
                                        </button>
                                        {
                                            getOpenDestination && (
                                                <List
                                                    component="nav"
                                                    className="animate__slideInDown z-index-100 bg-white d-block"
                                                >
                                                    {
                                                        arrayPropertyDestination.map((item, index) => (
                                                            <ListItem
                                                                key={index}
                                                                className="dropdown-item text-nowrap text-truncate font-family-raleway-regular width-100p"
                                                                onClick={() => {
                                                                    const find = arrayDestination.find(element => element.id == item.id);

                                                                    if (find === undefined || find === 'undefined') {
                                                                        arrayDestination.push({"id": item.id});
                                                                        itemsTags.push({"id": `Destinación: ${item.name}`, "index": item.id, "value": item.name, "type": "destination"});
                                                                    } else {
                                                                        arrayDestination.forEach((value, index, map) => {
                                                                            if (value.id === item.id) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        itemsTags.forEach((value, index, map) => {
                                                                            if (value.id === `Destinación: ${item.name}`) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        if (arrayDestination.length === 0) {
                                                                            setPropertyDestinationOpen(false);
                                                                            setOpenDestination(false);
                                                                        }
                                                                    }

                                                                    setPropertyDestination(arrayDestination);
                                                                    setArrayTags(itemsTags);

                                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, arrayDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                {
                                                                    (arrayDestination.find(element => element.id == item.id) === undefined) ?
                                                                        (
                                                                            <Display.Icons.CheckBoxOutlineBlankOutlinedIcon/>
                                                                        )
                                                                    :
                                                                        (
                                                                            <Display.Icons.CheckBoxOutlinedIcon/>
                                                                        )
                                                                }
                                                                </ListItemIcon>
                                                                <ListItemText className="text-truncate" primary={item.name}/>
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenPropertyState(getPropertyStateOpen)}
                        >
                            Estados de un inmueble
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getPropertyStateOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getPropertyStateOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary dropdown-toggle btn-Dropdown-light font-family-raleway-regular font-size-16 color-primary height-40 width-100p"
                                            onClick={handleOpenState}
                                        >
                                            Seleccionar
                                        </button>
                                        {
                                            getOpenState && (
                                                <List
                                                    component="nav"
                                                    className="animate__slideInDown z-index-100 bg-white d-block"
                                                >
                                                    {
                                                        arrayPropertyState.map((item, index) => (
                                                            <ListItem
                                                                key={index}
                                                                className="dropdown-item text-nowrap text-truncate font-family-raleway-regular width-100p"
                                                                onClick={() => {
                                                                    const find = arrayState.find(element => element.id == item.id);

                                                                    if (find === undefined || find === 'undefined') {
                                                                        arrayState.push({"id": item.id});
                                                                        itemsTags.push({"id": `Estado: ${item.name}`, "index": item.id, "value": item.name, "type": "state"});
                                                                    } else {
                                                                        arrayState.forEach((value, index, map) => {
                                                                            if (value.id === item.id) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        itemsTags.forEach((value, index, map) => {
                                                                            if (value.id === `Estado: ${item.name}`) {
                                                                                map.splice(index, 1);
                                                                            }
                                                                        });

                                                                        if (arrayState.length === 0) {
                                                                            setPropertyStateOpen(false);
                                                                            setOpenState(false);
                                                                        }
                                                                    }

                                                                    setPropertyState(arrayState);
                                                                    setArrayTags(itemsTags);

                                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, arrayState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    {
                                                                        (arrayState.find(element => element.id == item.id) === undefined) ?
                                                                            (
                                                                                <Display.Icons.CheckBoxOutlineBlankOutlinedIcon/>
                                                                            )
                                                                        :
                                                                            (
                                                                                <Display.Icons.CheckBoxOutlinedIcon/>
                                                                            )
                                                                    }
                                                                </ListItemIcon>
                                                                <ListItemText className="text-truncate" primary={item.name}/>
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenRoom(getRoomOpen)}
                        >
                            Habitaciones
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getRoomOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getRoomOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="input-group height-40">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getRoom >= 1){
                                                        number = parseInt(getRoom) - 1;
                                                    } else {
                                                        number = 0;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'room');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Habitación: ${number}`, "index": 0, "value": number, "type": "room"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'room') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Habitación: ${number}`, "index": 0, "value": number, "type": "room"});
                                                        }
                                                    }

                                                    setRoom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, number === 0 ? '' : number, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--8">-</div>
                                            </button>
                                            <input
                                                type="number"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getRoom}
                                                onChange={(e) => {
                                                    let number;

                                                    if (e.target.value === "" || e.target.value === 0 || e.target.value < 0) {
                                                        number = 0;
                                                    }else {
                                                        number = e.target.value;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'room');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Habitación: ${number}`, "index": 0, "value": number, "type": "room"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'room') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Habitación: ${number}`, "index": 0, "value": number, "type": "room"});
                                                        }
                                                    }

                                                    setRoom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, number === 0 ? '' : number, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getRoom === "" || getRoom === 0 || getRoom === null){
                                                        number = 1;
                                                    } else {
                                                        number = parseInt(getRoom) + 1;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'room');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({
                                                                "id": `Habitación: ${number}`,
                                                                "index": 0,
                                                                "value": number,
                                                                "type": "room"
                                                            });
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'room') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            if(number > 0) {
                                                                itemsTags.push({"id": `Habitación: ${number}`, "index": 0, "value": number, "type": "room"});
                                                            }
                                                        }
                                                    }

                                                    setRoom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, number === 0 ? '' : number, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--6">+</div>
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenBathroom(getBathroomOpen)}
                        >
                            Baños
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getBathroomOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getBathroomOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="input-group height-40">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getBathroom >= 1){
                                                        number = parseInt(getBathroom) - 1;
                                                    } else {
                                                        number = 0;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'bathroom');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Baño: ${number}`, "index": 0, "value": number, "type": "bathroom"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'bathroom') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Baño: ${number}`, "index": 0, "value": number, "type": "bathroom"});
                                                        }
                                                    }

                                                    setBathroom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, number === 0 ? '' : number, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--8">-</div>
                                            </button>
                                            <input
                                                type="number"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getBathroom}
                                                onChange={(e) => {
                                                    let number;

                                                    if (e.target.value === "" || e.target.value === 0 || e.target.value < 0) {
                                                        number = 0;
                                                    }else {
                                                        number = e.target.value;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'bathroom');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({
                                                                "id": `Baño: ${number}`,
                                                                "index": 0,
                                                                "value": number,
                                                                "type": "bathroom"
                                                            });}
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'bathroom') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Baño: ${number}`, "index": 0, "value": number, "type": "bathroom"});
                                                        }
                                                    }

                                                    setBathroom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, number === 0 ? '' : number, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getBathroom === "" || getBathroom === 0 || getBathroom === null){
                                                        number = 1;
                                                    } else {
                                                        number = parseInt(getBathroom) + 1;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'bathroom');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Baño: ${number}`, "index": 0, "value": number, "type": "bathroom"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'bathroom') {
                                                                map.splice(index, 1);
                                                            }
                                                        });
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Baño: ${number}`, "index": 0, "value": number, "type": "bathroom"});
                                                        }
                                                    }

                                                    setBathroom(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, number === 0 ? '' : number, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--6">+</div>
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenGarage(getGarageOpen)}
                        >
                            Garaje
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getGarageOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getGarageOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="input-group height-40">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getGarage >= 1){
                                                        number = parseInt(getGarage) - 1;
                                                    } else {
                                                        number = 0;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'garage');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'garage') {
                                                                map.splice(index, 1);
                                                            }
                                                        });

                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    }

                                                    setGarage(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, number === 0 ? '' : number, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--8">-</div>
                                            </button>
                                            <input
                                                type="number"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getGarage}
                                                onChange={(e) => {
                                                    let number;

                                                    if (e.target.value === "" || e.target.value === 0 || e.target.value < 0) {
                                                        number = 0;
                                                    }else {
                                                        number = e.target.value;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'garage');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'garage') {
                                                                map.splice(index, 1);
                                                            }
                                                        });

                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    }

                                                    setGarage(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, number === 0 ? '' : number, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-primary-light font-family-open-sans-regular font-size-24 p-2 text-primary width-40 height-40"
                                                onClick={() => {
                                                    let number;

                                                    if (getGarage === "" || getGarage === 0 || getGarage === null){
                                                        number = 1;
                                                    } else {
                                                        number = parseInt(getGarage) + 1;
                                                    }

                                                    const find = itemsTags.find(element => element.type == 'garage');

                                                    if (find === undefined || find === 'undefined'){
                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    } else {
                                                        itemsTags.forEach((value, index, map) => {
                                                            if(value.type === 'garage') {
                                                                map.splice(index, 1);
                                                            }
                                                        });

                                                        if(number > 0) {
                                                            itemsTags.push({"id": `Garaje: ${number}`, "index": 0, "value": number, "type": "garage"});
                                                        }
                                                    }

                                                    setGarage(number === 0 ? '' : number);
                                                    setArrayTags(itemsTags);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, number === 0 ? '' : number, getRange, getArea1, getArea2, getYear1, getYear2);
                                                }}
                                            >
                                                <div className="top--6">+</div>
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenArea(getAreaOpen)}
                        >
                            Área
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getAreaOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                        :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getAreaOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <div className="input-group height-40">
                                            <input
                                                type="number"
                                                placeholder="Desde"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getArea1}
                                                onChange={(e) => {
                                                    setArea1(e.target.value);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, e.target.value, getArea2, getYear1, getYear2);
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="input-group height-40">
                                            <input
                                                type="number"
                                                placeholder="Hasta"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getArea2}
                                                onChange={(e) => {
                                                    setArea2(e.target.value);

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, e.target.value, getYear1, getYear2);
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenStrata(getStrataOpen)}
                        >
                            Estrato
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getStrataOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                    :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getStrataOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-15 m-auto text-center">
                                <Grid container spacing={1}>
                                    {
                                        arrayStrata.map((item, index) => (
                                            <Grid item xs={2} key={index}>
                                                <button
                                                    key={index}
                                                    type="button"
                                                    name="strata1"
                                                    className="btn btn-sm btn-outline font-family-open-sans-regular font-size-14 width-40 height-30"
                                                    onClick={() => {
                                                        const find = arrayStrato.find(element => element.id == item.id);

                                                        if (find === undefined || find === 'undefined'){
                                                            arrayStrato.push({"id": item.id});
                                                            itemsTags.push({"id": `Estrato: ${item.name}`, "index": item.id, "value": item.name, "type": "strata"});
                                                        } else {
                                                            arrayStrato.forEach((value, index, map) => {
                                                                if(value.id === item.id) {
                                                                    map.splice(index, 1);
                                                                }
                                                            });

                                                            itemsTags.forEach((value, index, map) => {
                                                                if(value.id === `Estrato: ${item.name}`) {
                                                                    map.splice(index, 1);
                                                                }
                                                            });

                                                            if (arrayStrato.length === 0) {
                                                                setStrataOpen(false);
                                                            }
                                                        }

                                                        setStrata(arrayStrato);
                                                        setArrayTags(itemsTags);

                                                        getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, arrayStrato, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, getYear2);
                                                    }}
                                                >
                                                    {
                                                        (arrayStrato.find(element => element.id == item.id) === undefined) ?
                                                            (
                                                                <div className="text-primary p-1 width-100p">
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        :
                                                            (
                                                                <div className="bg-primary text-white p-1 width-100p">
                                                                    {item.name}
                                                                </div>
                                                            )
                                                    }
                                                </button>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </List>
                        </Collapse>
                        <div className="border-bottom-primary"></div>
                        <ListItem
                            button
                            onClick={() => setHandleOpenYear(getYearOpen)}
                        >
                            Año de Construcción
                            <span className="position-absolute float-right text-right m-2">
                                {
                                    getYearOpen ?
                                        <Display.Icons.ExpandLessIcon />
                                        :
                                        <Display.Icons.ExpandMoreIcon />
                                }
                            </span>
                        </ListItem>
                        <Collapse in={getYearOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className="padding-10 align-items-center justify-content-center m-auto text-center">
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <div className="input-group height-40">
                                            <input
                                                type="number"
                                                maxLength="4"
                                                placeholder="Desde"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getYear1}
                                                onChange={(e) => {
                                                    setYear1(e.target.value)

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, e.target.value, getYear2)
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="input-group height-40">
                                            <input
                                                type="number"
                                                maxLength="4"
                                                placeholder="Hasta"
                                                className="form-control font-family-open-sans-regular font-size-16 height-40 text-center border-1 border-primary"
                                                value={getYear2}
                                                onChange={(e) => {
                                                    setYear2(e.target.value)

                                                    getHandlePropertyList(0, getActionSearch, getPropertyType, getPropertyDestination, getPropertyState, getStrata, getRoom, getBathroom, getGarage, getRange, getArea1, getArea2, getYear1, e.target.value)
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </List>
                        </Collapse>
                    </List>
                </Paper>
            </Drawer>
        </>
    );
};
