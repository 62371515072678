import React from 'react';
import MuiButton from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    primaryLight: {
        backgroundColor: '#F0F7FF',
        border: '1px solid #F0F7FF !important',
        boxShadow: 'none !important',
        color: '#3A50D3',
        padding: '5px 10px !important',
        margin: theme.spacing(0.5),
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#F0F7FF',
            border: '1px solid #3A50D3 !important',
            color: '#3A50D3',
            boxShadow: 'none !important'
        }
    },
    white: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #FFFFFF !important',
        boxShadow: 'none !important',
        color: '#3A50D3',
        padding: '8px 15px !important',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #FFFFFF !important',
            color: '#3A50D3',
            boxShadow: 'none !important'
        }
    }
}));

export default function ButtonAction(props) {
    const classes = useStyles();
    const { label, color, variant, startIcon, endIcon, href, ...other } = props;

    if (color === 'primaryLight'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.primaryLight }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }

    if (color === 'white'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                href={href || ""}
                classes={{ root: classes.white }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }
}