import React from 'react';
import { Link as MuiLink } from '@material-ui/core';

export default function Link(props) {
    const { label, href, ...other } = props;

    return (
        <MuiLink
            color="inherit"
            href={href || "#"}
            {...other}
        >
            {label}
        </MuiLink>
    );
}