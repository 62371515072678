import Services from '../../services/Services';

export async function getPropertyDestinationList(data) {
    let message;
    const res = await Services.api(`properties/destinations/list`, data, 'post');

    if (res.status === 200) {
        message = res.data;
    } else {
        message = res.message;
    }

    return {
        "status": res.status,
        "message": message
    };
}