import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        position: 'relative',
        height: '100%',
        textAlign: 'center'
    }
}));

export default function Loading(props) {
    const classes = useStyles();
    const { type, animation, width, height, ...other } = props;

    if (type === 'spin') {
        return (
            <div className={classes.root}>
                <CircularProgress {...other} />
            </div>
        );
    } else {
        return (
            <Skeleton variant={type} animation={animation} width={width} height={height} {...other} />
        );
    }
}