import React from 'react';

export default function Select(props) {
    const { label, name, defaultValue, onChange, arrayDropdown, ...other } = props;

    return (
        <select
            name={name}
            onChange={onChange || ""}
            {...other}
        >
            <option value="">{label}</option>
            {
                arrayDropdown.map((item, index) => (
                    <option key={index} selected={item.id === defaultValue ? 'selected' : ''} value={item.id}>{item.name}</option>
                ))
            }
        </select>
    );
}