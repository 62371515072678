import React from 'react';
import logo from "../../assets/images/logo/logo.png";

export default function Logo(props) {
    const { ...other } = props;

    return (
        <div className="sectionLogo">
            <a href="/"><img src={logo} alt="" /></a>
        </div>
    );
}
