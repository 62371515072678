import React from 'react';
import MuiBackdrop from '@material-ui/core/Backdrop';
import Display from '../display/Display';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#85BF02'
    }
}));

export default function Backdrop(props) {
    const classes = useStyles();
    const { open } = props;

    return (
        <MuiBackdrop 
            className={classes.backdrop} 
            open={open} 
            transitionDuration={2000}
        >
            <Display.Icons.CircularProgressIcon />
        </MuiBackdrop>
    );
}