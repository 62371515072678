import React  from 'react';

export default function Pagination(props) {
    const { pageCount, pageIndex, setChangePage } = props;
    const pagination= [];

    if (pageCount < 2) return null;

    let prev_num = true;

    for (let i = 0; i < pageCount; i++) {
        if (i < 1 || (i + 1) === pageCount || Math.abs(pageIndex - i) < 3) {
            prev_num = true;
            pagination.push(i);
        }
        else if (prev_num) {
            prev_num = false;
            pagination.push(null);
        }
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination m-auto justify-content-center align-items-center">
                {
                    pagination.map((item, index) => {
                        if (item === null) {
                            return (
                                <li
                                    key={index}
                                    className="page-item font-family-open-sans-semibold font-size-22"
                                >
                                    <div className="page-link color-primary">...</div>
                                </li>
                            );
                        }

                        if (item === pageIndex) {
                            return (
                                <li
                                    key={index}
                                    className="page-item active font-family-open-sans-semibold font-size-22"
                                >
                                    <div className="page-link active">{1 + item}</div>
                                </li>
                            );
                        }

                        return (
                            <li
                                key={index}
                                className="page-item color-primary font-family-open-sans-semibold font-size-22"
                                onClick={() => setChangePage(item)}
                            >
                                <div className="page-link color-primary">{1 + item}</div>
                            </li>
                        );
                    })
                }
            </ul>
        </nav>
    );
}
