import React from 'react';
import MuiButton from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    blue: {
        backgroundColor: '#2435C8',
        border: '1px solid #2435C8 !important',
        boxShadow: 'none !important',
        color: '#f9f9f9',
        padding: '8px 15px !important',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2435C8',
            border: '1px solid #2435C8 !important',
            color: '#f9f9f9',
            boxShadow: 'none !important'
        }
    },
    blueLight: {
        color: '#2435C8',
        borderColor: '#2435C8',
        backgroundColor: '#F2F8FF',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f9f9f9',
            color: '#2435C8',
            borderColor: '#2435C8'
        }
    },
    white: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #FFFFFF !important',
        boxShadow: 'none !important',
        color: '#3A50D3',
        padding: '8px 15px !important',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #FFFFFF !important',
            color: '#3A50D3',
            boxShadow: 'none !important'
        }
    },
    whiteBorderBlue: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #2435C8 !important',
        boxShadow: 'none !important',
        color: '#2435C8',
        padding: '3px 3px !important',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            border: '1px solid #2435C8 !important',
            color: '#2435C8',
            boxShadow: 'none !important'
        }
    }
}));

export default function Button(props) {
    const classes = useStyles();
    const { label, color, variant, startIcon, endIcon, ...other } = props;

    if (color === 'blue'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.blue }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }

    if (color === 'blueLight'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.blueLight }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }

    if (color === 'white'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.white }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }

    if (color === 'whiteBorderBlue'){
        return (
            <MuiButton
                color="inherit"
                variant={variant || "standard"}
                startIcon={startIcon || ""}
                endIcon={endIcon || ""}
                classes={{ root: classes.whiteBorderBlue }}
                {...other}
            >
                {label}
            </MuiButton>
        );
    }
}
