import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Controls from "../../components/controls/Controls";
import Display from "../../components/display/Display";
import banner from '../../assets/images/home_imagen_financar.png';
import './Home.scss';

export default function HomeView(){
    const [getActionSearch, setActionSearch] = useState("Arriendo");
    const [getSearch, setSearch] = useState("");
    const [scrollTop, setScrollTop] = useState(0);
    const navigate = useNavigate();

    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    },[]);

    useEffect(() => {
        const header = document.querySelector(".subheader");

        if (scrollTop > 600) {
            header.classList.add('sticky');
            header.classList.add('animate__animated');
            header.classList.add('animate__slideInDown');
        } else {
            header.classList.remove('sticky');
            header.classList.remove('animate__animated');
            header.classList.remove('animate__slideInDown');
        }
    }, [scrollTop]);

    const setHandleSearch = async (e) => {
        e.preventDefault();

        if (getActionSearch !== '' && getSearch !== '') {
            await navigate(`/propiedades?action=${getActionSearch}&search=${getSearch}`, { replace: true });
        }
    };

    return (
        <>
            <div className="subheader">
                <div className="container">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            <Controls.SearchSimple
                                label="Powered by Mobili"
                                classText="form-control form-control-primary bg-primary-light font-family-raleway-bold font-size-18 color-dark height-40"
                                nameText="search"
                                valueText={getSearch}
                                placeholder="Buscar por ubicación, zona y dirección"
                                onChangeText={e => setSearch(e.target.value)}
                                onKeyPress={e => e.charCode === 13 && setHandleSearch(e, getActionSearch)}
                                classButton="btn btn-outline-primary text-white width-40 height-40 p-0"
                                onClickButton={e => setHandleSearch(e, getActionSearch)}
                                iconButton={<Display.Icons.SearchIcon />}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className="sectionBanner" style={{ backgroundImage: `url(${banner})` }}>
                <div className="container">
                    <h1 className="titleBanner font-family-raleway-bold font-size-50 color-title">Encuentra tu inmueble</h1>
                    <div className="buttonsBanner">
                        <Controls.Button
                            label="Arriendo"
                            color={getActionSearch === 'Arriendo' ? "blue" : "white"}
                            variant="contained"
                            className="font-family-raleway-bold noBorderRadius font-size-18"
                            onClick={e => setActionSearch('Arriendo')}
                        />
                        <Controls.Button
                            label="Compra"
                            color={getActionSearch === 'Compra' ? "blue" : "white"}
                            variant="contained"
                            className="font-family-raleway-bold noBorderRadius borderLR font-size-18"
                            onClick={e => setActionSearch('Compra')}
                        />
                        <Controls.ButtonAction
                            label="Proyectos"
                            color="white"
                            variant="contained"
                            className="font-family-raleway-bold noBorderRadius font-size-18"
                            href="/"
                        />
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            <div className="sectionSearch">
                                <Controls.SearchSimple
                                    label="Powered by Mobili"
                                    classText="form-control form-control-primary bg-primary-light font-family-raleway-bold font-size-18 color-dark height-60"
                                    nameText="search"
                                    valueText={getSearch}
                                    placeholder="Buscar por ubicación, zona y dirección"
                                    onChangeText={e => setSearch(e.target.value)}
                                    onKeyPress={e => e.charCode === 13 && setHandleSearch(e, getActionSearch)}
                                    classButton="btn btn-outline-primary text-white width-60 height-60 p-0"
                                    onClickButton={e => setHandleSearch(e, getActionSearch)}
                                    iconButton={<Display.Icons.SearchIcon />}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className="sectionContent">
                <div className="container">
                    <h2 className="font-family-raleway-bold font-size-40 color-dark">Inmuebles para ti</h2>
                </div>
            </div>
        </>
    );
};
