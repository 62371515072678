import SendOutlinedIcon from './icons/SendOutlinedIcon';
import AccountCircleOutlinedIcon from './icons/AccountCircleOutlinedIcon';
import AccountCircleIcon from './icons/AccountCircleIcon';
import CheckBoxOutlinedIcon from './icons/CheckBoxOutlinedIcon';
import CheckBoxOutlineBlankOutlinedIcon from './icons/CheckBoxOutlineBlankOutlinedIcon';
import CloseOutlinedIcon from './icons/CloseOutlinedIcon';
import ExpandLessIcon from './icons/ExpandLessIcon';
import ExpandMoreIcon from './icons/ExpandMoreIcon';
import FacebookIcon from './icons/FacebookIcon';
import HighlightOffOutlinedIcon from './icons/HighlightOffOutlinedIcon';
import InstagramIcon from './icons/InstagramIcon';
import KeyboardArrowUpIcon from './icons/KeyboardArrowUpIcon';
import LinkedInIcon from './icons/LinkedInIcon';
import SearchIcon  from './icons/SearchIcon';
import WhatsAppIcon  from './icons/WhatsAppIcon';
import YouTubeIcon  from './icons/YouTubeIcon';

const Icons = {
    SendOutlinedIcon,
    AccountCircleOutlinedIcon,
    AccountCircleIcon,
    CheckBoxOutlinedIcon,
    CheckBoxOutlineBlankOutlinedIcon,
    CloseOutlinedIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    FacebookIcon,
    HighlightOffOutlinedIcon,
    InstagramIcon,
    KeyboardArrowUpIcon,
    LinkedInIcon,
    SearchIcon,
    WhatsAppIcon,
    YouTubeIcon
};

export default Icons;
