import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Controls from "../../../components/controls/Controls";
import Display from "../../../components/display/Display";
import Logo from "../../../components/logo/Logo";

export default function Header(props) {
    const { ...other } = props;

    return (
        <AppBar className="height-60 bg-white" position="sticky">
            <Toolbar className="d-block width-100p">
                <Logo />
                <div className="sectionNav d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                    <ul className="nav nav-pills height-60">
                        <li className="nav-item height-60">
                            <a className="nav-link noBorderRadius height-60 font-family-raleway-bold font-size-18 color-primary active" href="/">Servicios</a>
                        </li>
                        <li className="nav-item height-60">
                            <a className="nav-link noBorderRadius height-60 font-family-raleway-bold font-size-18 color-primary" href="/">Blog</a>
                        </li>
                        <li className="nav-item height-60">
                            <a className="nav-link noBorderRadius height-60 font-family-raleway-bold font-size-18 color-primary" href="/">Conócenos</a>
                        </li>
                        <li className="nav-item height-60">
                            <a className="nav-link noBorderRadius height-60 font-family-raleway-bold font-size-18 color-primary" href="/">Contáctanos</a>
                        </li>
                    </ul>
                </div>
                <div className="sectionLogin d-xs-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                    <Controls.ButtonAction
                        label="Ingresar"
                        color="primaryLight"
                        variant="contained"
                        endIcon={<Display.Icons.AccountCircleIcon />}
                        href="https://mobili.joonik.com/"
                        className="font-family-raleway-bold font-size-16"
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
}
