import React  from 'react';
import LayoutView from './pages/layout/LayoutView';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'animate.css/animate.min.css';
import './App.scss';

export default function App() {
    return (
        <LayoutView path="/*" />
    );
};