import React from 'react';

export default function SearchSimple(props) {
    const { label, classText, nameText, valueText, placeholder, onChangeText, classButton, onClickButton, iconButton, ...other } = props;

    return (
        <>
            <div className="input-group">
                <input
                    type="text"
                    className={classText}
                    name={nameText}
                    placeholder={placeholder}
                    value={valueText || ""}
                    onChange={onChangeText || ""}
                    {...other}
                />
                <button
                    type="button"
                    className={classButton}
                    onClick={onClickButton || false}
                >
                    {iconButton}
                </button>
            </div>
            <div className="font-family-raleway-regular font-size-12 color-powered text-right">
                {label}
            </div>
        </>
    );
}
