import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {
    // --------------------------------
    // Función para conectar a la api por parámetro a cualquier endpoint
    //
    async api(url, data, type) {
        const apiUrl = `${process.env.REACT_APP_URL_API}${url}`;

        if (type === 'post') {
            return await axios.post(apiUrl, data)
                .then(res =>{
                    return res;
                }).catch(catchError =>{
                    return handleError(catchError);
                })
        } else {
            return await axios.get(apiUrl, data)
                .then(res =>{
                    return res;
                }).catch(catchError =>{
                    return handleError(catchError);
                })
        }
    }
};

// --------------------------------
// Manejo de errores
// --------------------------------
function handleError(error) {
    let status  = ''
    let message = ''
    let res     = JSON.parse(error.request.response)

    if (error.request.status) {
        switch (error.request.status) {
            case 401:
                status  = 401
                message =  `Usted no está autenticado.`
                break
            case 403:
                status  = 403
                message = `Usted no tiene permiso en esta sección.`
                break
            case 404:
                status  = 404
                message = `No existe lo que esta buscando.`
                break
            case 405:
                status  = 405
                message = `El metodo que esta buscando no existe.`
                break
            case 409:
                status  = 409
                message = `Hay un conflicto de recursos intente de nuevo mas tarde.`
                break
            case 422:
                status  = 422
                if (res.error){
                    message = res.error
                } else if (res.errors){
                    const errors = Object.values(res.errors)

                    for (let i=0; i < errors.length; i++) {
                        message += errors[i]
                    }
                }
                break
            default:
                break
        }
    } else {
        message = `Error de conexión de red`
    }

    return {
        "status": status,
        "message" : message
    }
}
