import Alert from './Alert';
import Backdrop from './Backdrop';
import Loading from './Loading';

const Feedback = {
    Alert,
    Backdrop,
    Loading
};

export default Feedback;