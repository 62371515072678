import React from 'react';
import Grid from "@material-ui/core/Grid";
import Display from "../../../components/display/Display";
import Logo from "../../../components/logo/Logo";

export default function Footer(props) {
    const { ...other } = props;

    return (
        <footer>
            <div className="container">
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="d-flex width-100p mb-4">
                            <Logo />
                        </div>

                        <div className="d-flex width-100p font-family-raleway-medium font-size-14 color-dark">
                            Desde 1983, en Financar Inmobiliaria ofrecemos servicio de avalúo, arriendo, venta, gestión de proyectos y administración de inmuebles.
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="font-family-raleway-bold font-size-20 color-title-footer">
                            Oficinas
                        </div>
                        <div className="font-family-raleway-medium font-size-18 color-title-footer mb-4">
                            Barranquilla, Colombia
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark mb-4">
                            SEDE PRADO Carrera 56 # 74 - 70 Piso 1
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark">
                            SEDE BUENAVISTA BC EMPRESARIAL Cra 53 Cl 110 esquina, Local 7, BC Empresarial
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="font-family-raleway-bold font-size-20 color-title-footer mb-5">
                            Contacto
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark mb-5">
                            PBX: +57 (5) 3303333
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark">
                            contacto1@financar.com.co
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div className="font-family-raleway-bold font-size-20 color-title-footer mb-5">
                            Acerca de
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark mb-5">
                            Política de tratamiento de datos
                        </div>
                        <div className="font-family-raleway-medium font-size-14 color-dark">
                            Quejas y reclamos
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <div className="mb-4 mt-2">
                            <Display.Icons.FacebookIcon className="color-primary width-32 height-24" />
                            <Display.Icons.InstagramIcon className="color-primary width-32 height-24" />
                            <Display.Icons.WhatsAppIcon className="color-primary width-32 height-24" />
                            <Display.Icons.YouTubeIcon className="color-primary width-32 height-24" />
                            <Display.Icons.LinkedInIcon className="color-primary width-32 height-24" />
                        </div>
                        <hr className="width-100p bg-primary-hover height-1 mb-4" />
                        <div className="font-family-raleway-bold font-size-13 color-title-footer">
                            Financar © 2021. Reservados todos los derechos.
                        </div>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
}
