import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeView from '../home/HomeView';
import PropertyView from '../property/PropertyView';
import Header from "./header/Header";
import Footer from './footer/Footer';
import Navigation from '../../components/navigation/Navigation';
import './Layout.scss';

const itemRoutes = [
    {
        path: "/",
        component: <HomeView />
    },
    {
        path: "/propiedades",
        component: <PropertyView />
    }
];

export default function LayoutView() {
    const [getRoutes, settRoutes] = useState(itemRoutes);

    return (
        <>
            <Header />

            <div className="mainContent">
                <Router>
                    <Routes>
                        {
                            getRoutes.map((item, index) => (
                                <Route key={index} path={item.path} element={item.component} />
                            ))
                        }
                    </Routes>
                </Router>
            </div>

            <Footer />

            <Navigation.ScrollTop />
        </>
    );
};
