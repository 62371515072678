import React from 'react';
import Grid from '@material-ui/core/Grid';
import Controls from "./Controls";

export default function SearchFull(props) {
    const { classDropdown, labelDropDown, arrayDropdown, classText, nameText, valueText, placeholder, onChangeText, classButton, onClickButton, iconButton, classButtonDrawer, labelButtonDrawer, colorButtonDrawer, valueDrawer, setAction, setChangeSearching, setSearching, setDrawer, ...other } = props;

    const handleOpen = (event) => {
        setDrawer(!valueDrawer);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={9} md={10} lg={10}>
                <div className="input-group">
                    <button
                        type="button"
                        className={classDropdown}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {labelDropDown}
                    </button>
                    <ul className="dropdown-menu">
                        {
                            arrayDropdown.map((item, index) => (
                                <li
                                    key={index}
                                    className="dropdown-item"
                                    onClick={e => {
                                        setAction(item.id);
                                        setChangeSearching(item.id);
                                    }}
                                >
                                    {item.name}
                                </li>
                            ))
                        }
                    </ul>
                    <input
                        type="text"
                        className={classText}
                        name={nameText}
                        placeholder={placeholder}
                        value={valueText || ""}
                        onChange={onChangeText || ""}
                        {...other}
                    />
                    <button
                        type="button"
                        className={classButton}
                        onClick={onClickButton || false}
                    >
                        {iconButton}
                    </button>
                </div>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} className="text-center">
                <Controls.Button
                    label={labelButtonDrawer}
                    color={colorButtonDrawer}
                    variant="outlined"
                    onClick={handleOpen}
                    className={classButtonDrawer}
                />
            </Grid>
        </Grid>
    );
}
