import Button from './Button';
import ButtonAction from './ButtonAction';
import Select from './Select';
import SearchSimple from './SearchSimple';
import SearchFull from './SearchFull';

const Controls = {
    Button,
    ButtonAction,
    Select,
    SearchSimple,
    SearchFull
};

export default Controls;