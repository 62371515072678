import React from 'react';
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Display from "../display/Display";

export default function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className="position-fixed back-to-top">
                <Fab color="inherit" size="small" aria-label="back to top" className="bg-white">
                    <Display.Icons.KeyboardArrowUpIcon className="color-primary" />
                </Fab>
            </div>
        </Zoom>
    );
}
